import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

// antd components module
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { ZeroPaddingPipe } from './pipes/zero-padding.pipe'
import { StateColor } from './pipes/state-color.pipe'
import { TimeRemainingPipe } from './pipes/time-remaining.pipe'
import { AntdModule } from './antd.module'
import { AutoSuggestDirective } from './utilities/directive/auto-suggest.directive'
import { DateAgoPipe } from './pipes/date-ago.pipe'
import { FileSizePipe } from './pipes/file-size.pipe'
import { FieldMappingPipe } from './pipes/field-mapping.pipe'
import { RoleMappingPipe } from './pipes/roles.pipe'
import { DurantionPipe } from './pipes/duration.pipe'


const MODULES = [
  CommonModule,
  RouterModule,
  AntdModule,
  FormsModule,
  ReactiveFormsModule,
]

@NgModule({
  imports: [...MODULES],
  declarations: [
    AutoSuggestDirective,
    DateAgoPipe,
    ZeroPaddingPipe,
    FileSizePipe,
    FieldMappingPipe,
    RoleMappingPipe,
    StateColor,
    TimeRemainingPipe,
    DurantionPipe

  ],
  exports: [
    ...MODULES,
    AutoSuggestDirective,
    DateAgoPipe,
    ZeroPaddingPipe,
    FileSizePipe,
    FieldMappingPipe,
    RoleMappingPipe,
    StateColor,
    TimeRemainingPipe,
    DurantionPipe
  ],
})
export class SharedModule {}
