import { Customer } from './customer.model'

export class Attachment {
  id: string
  createdAt: Date
  updatedAt: Date
  name: string
  mimeType: string
  size: number
  content: string
  author: Customer
  source: string 

  constructor(data?: any) {
    this.id = data?.id
    this.createdAt = data?.createdAt
    this.updatedAt = data?.updatedAt
    this.name = data?.name
    this.mimeType = data?.mimeType
    this.size = data?.size
    this.content = data?.content
    this.source = data.source
    this.author = new Customer(data?.author)
  }
}
