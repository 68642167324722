export enum StorageKeys {
  AccessToken = 'accessToken',
  CurrentUser = 'currentUser',
  Remember = 'remember',
}

export enum UserRole {
  Clinic = 'Clinic',
  Lab = 'Lab',
  Staff = 'Staff',
}

export const UserRoleOptions = [
  { name: UserRole.Clinic, value: 'clinic' },
  { name: UserRole.Lab, value: 'lab' },
  { name: UserRole.Staff, value: 'lab_staff' },
]

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export const UserStatusOptions = [
  { name: UserStatus.Active, value: 'active' },
  { name: UserStatus.Inactive, value: 'inactive' },
]

export class User {
  id: number = 0
  email: string = ''
  name: string = ''
  role: string = ''
  status: string = ''
  address?: string
  clinicName?: string
  phone?: string
  website?: string
  meditLinkAuthCompleted?: boolean
  meditLinkUserProfile?: MeditLinkUserProfile | null
  profileImageUrl: string = ''
  createdAt: Date = new Date()
  updatedAt: Date = new Date()

  constructor(data?: any) {
    if (!data) {
      return
    }

    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.email = data.email
    this.name = data.name
    this.role = data.role
    this.status = data.status
    this.address = data.address
    this.clinicName = data.clinicName ?? ''
    this.phone = data.phone ?? ''
    this.website = data.website ?? ''
    this.profileImageUrl = data.profileImageUrl
    if (data.meditLinkUserProfile){
      this.meditLinkUserProfile = new MeditLinkUserProfile()
      this.meditLinkUserProfile.email = data.meditLinkUserProfile.email || ''
      this.meditLinkUserProfile.name = data.meditLinkUserProfile.name || ''
    }
  }
}

export class LoginPayload {
  email: string = ''
  password: string = ''
}

export class ChangePasswordPayload {
  currentPassword?: string
  newPassword: string = ''
}

export class UserCreatePayload {
  email: string = ''
  name: string = ''
  password: string =' '
}

export class SignupPayload {
  email: string = ''
  name: string = ''
  password: string =' '
  role: string = ''
  address: string =' '
  website: string = ''
  phone: string = ''
  clinicName: string = ''
}

export class ResetPasswordRequestPayload {
  email: string = ''
}

export class ResetPasswordPayload {
  token: string = ''
  newPassword: string = ''
}


export class MeditLinkUserProfile {
  email: string = ''
  name: string = ''
}